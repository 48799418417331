<template>

  <section class="bg-brand-dark-grey text-white py-2 text-center text-sm-start">
    <div class="px-5  d-flex flex-row  breadcrumb-menu align-items-center justify-content-between">
      <nav aria-label="breadcrumb ">
        <ol class="breadcrumb  ps-2" v-if="componentData && componentData!=='home'">
          <li class="breadcrumb-item">
            <router-link :to="`/${route.params.language}/home`" class="text-white text-decoration-none">
              {{ route.params.language==="en"? "Home" : "Accueil"}}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span class="text-white text-capitalize fw-bold"> {{componentData}}
            </span>
          </li>
        </ol>
      </nav>
  
      <div class="d-flex flex-row breadcrumb icons-menu align-items-center">
        <div id="smIcons" class="d-flex flex-row d-none">
          <template v-for="link,index in siteData?.socialLinks " :key="index">
            <a :href="link.url" target="_blank" tabindex="5" :alt="link.title">
              <i :class="`bi bi-${link.title.toLowerCase()} social-icon pe-2 text-white`"></i>
              <span class="visually-hidden-focusable">Click to share on social media</span>
            </a>
          </template>
        </div>

        <button id="smButton" class="share-button me-3 text-white">
          <i class="bi bi-share-fill social-icon ps-1 text-white" v-if="siteData?.socialLinks.length>0"></i>
          <span class="visually-hidden-focusable text-white">Click here to share socials</span>
        </button>

        <!-- {{searchLength}} -->
        <!-- <router-link :to="`/${route.params.language}/search`" >
          <i class="bi bi-search  pe-2 text-white" aria-hidden="true"></i>
          <span class="visually-hidden-focusable text-white">Click here to search</span>
        </router-link> -->

        <!-- <SearchBar /> -->

      </div>

    </div>

  </section>
</template>

<script >
import { useRoute } from "vue-router";
// import { fetchSearch } from "@/api";
export default {
  props: {
    componentData: String,
    siteData: [],
  },

  data() {
    return {
      route: useRoute(),
      show: false,
      content: [],
      // searchLength: 0
    };
  },

  // async mounted(){
  
  //     let search = await fetchSearch(this.$route.params.language);
  //     console.log(search.filter.length);
  //     this.searchLength = search.length;
    
  // },
  
};
</script>

<style lang="scss">
.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0 !important;
}
.social-icon {
  cursor: pointer;
}

.share-button {
  border: none;
  background: none;
  color: var(--brand-white);
}

.breadcrumb-menu {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

@media screen and (max-width: 992px) {
  .breadcrumb-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .breadcrumb-menu {
    // padding-left: 1rem !important;
    padding-right: 3rem !important;
  }
}

@media screen and (max-width: 1200px) {
  .icons-menu {
    padding-right: 0 !important;
  }
}
</style>
