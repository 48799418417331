<template>
  <div class="text-sm-start image-position pb-5 ">
   
      <TitleComponent :title="componentData?.title" />
   
      <span class="text-brand-dark-grey fw-bold" v-if="componentData?.startDate || componentData?.endDate"> {{ `${activityDateRange(componentData?.startDate, componentData?.endDate)}`}}</span>
   
    <div class="lh-base text-justify">
      <p v-if="componentData?.publicationDate" class="date-header text-brand-blue">{{componentData?.publicationDate}}</p>
      <p class="text-brand-dark-grey" v-if="componentData?.content?.html || componentData?.description" v-html="componentData?.content?.html ?? componentData?.description">
      </p>
    </div>
  </div>
</template>
<script>



export default {
    props: {
        componentData: [],
    },

    methods: {

      activityDateRange(startDate, endDate) {
      const sday = new Date(startDate);
      const eday = new Date(endDate);

      let sm = sday.toLocaleDateString("default", { month: "short" }); // Months start at 0!
      let em =
        sday.toLocaleDateString("default", { month: "short" }) ===
        eday.toLocaleDateString("default", { month: "short" })
          ? null
          : eday.toLocaleDateString("default", { month: "short" }); // Months start at 0!
      let sd = sday.getDate();
      let ed = eday.getDate();

      if (sd < 10) sd = "0" + sd;
      if (ed < 10) ed = "0" + ed;



      return (sd ===ed) ? sm + " " + sd : sm + " " + sd + " - " + (em ? em + " " : "") + ed;
    },
  },
};
</script>

<style lang="scss">
.date-header {
  font-size: 12px;
}
</style>