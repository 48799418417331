<template>

  <TitleComponent :title="componentData?.title" />

  <!-- <h4 class=" text-secondary fw-bold" v-if="componentData?.title">{{componentData?.title}}</h4> -->
  <div class="p-5 pt-3 text-sm-start image-position" v-if="componentData.length>0 || componentData.listItems ">

    <div class="row">
      <div class="fw-bold border-bottom pb-2">
        <div class="row">
          <div class="" :class="smAndSmaller ? 'col-sm-6' : 'col-md-6' ">{{componentData?.slug === 'table-component' ? 'Agency' : 'Activity' }}</div>
          <div class="" :class="smAndSmaller ? 'col-sm-6' : 'col-md-6' ">{{componentData?.slug === 'table-component' ? 'Awards' : 'Description' }}</div>
        </div>
      </div>
      <div class="border-bottom" v-for="(data, index) in componentData?.listItems ?? componentData.filter(c=> c.slug!== 'text-component' ) " :key="index">
        <div class="row py-3 table-row">
          <div class="fw-bold" :class="smAndSmaller ? 'col-sm-6' : 'col-md-6' ">
            <p class="mb-2 text-brand-dark-grey fw-bold" v-if="data.title">{{data.title}}</p>
            <!-- <span> {{data.startDate}}</span> <span v-if="data.endDate"> - {{data.endDate}}</span> -->
            <span class="text-brand-blue" v-if="data.startDate || data.endDate"> {{activityDateRange(data.startDate, data.endDate)}}</span>
            <p class="mb-2 " v-if="data.description">{{data.description}}</p>
          </div>

          <div class="" :class="smAndSmaller ? 'col-sm-6' : 'col-md-6' ">

            <p class="mb-0 text-brand-dark-grey" v-if="data?.activity?.html" v-html="data?.activity?.html"></p>
            <p class="mb-0 text-brand-dark-grey" v-if="data?.content" v-html="data?.content"></p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script >
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";

export default {
  data() {
    return {
      breakpoints: useBreakpoints(breakpointsBootstrapV5),
      // smAndSmaller: useBreakpoints.smaller("md")
    };
  },

  props: {
    componentData: [],
  },

  methods: {
    getMonthYear(d) {
      const today = new Date(d);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      return yyyy + "-" + mm + "-" + dd;
    },
    activityDateRange(startDate, endDate) {
      const sday = new Date(startDate);
      const eday = new Date(endDate);

      let sm = sday.toLocaleDateString("default", { month: "short" }); // Months start at 0!
      let em =
        sday.toLocaleDateString("default", { month: "short" }) ===
        eday.toLocaleDateString("default", { month: "short" })
          ? null
          : eday.toLocaleDateString("default", { month: "short" }); // Months start at 0!
      let sd = sday.getDate();
      let ed = eday.getDate();

      if (sd < 10) sd = "0" + sd;
      if (ed < 10) ed = "0" + ed;

      return sd === ed
        ? sm + " " + sd
        : sm + " " + sd + " - " + (em ? em + " " : "") + ed;
    },
  },
};
</script>

<style lang="scss">
.table-row {
  &:hover {
    background-color: var(--shades-200);
  }
}
</style>
